<script>
import { videoPlayer } from 'vue-video-player'
export default {
  name: 'Video',
  metaInfo: {
    title: 'Видео | OnlyDiamond',
    meta: [{ name: 'description', content: 'Видео компании OnlyDiamond' }],
  },
  components: {
    videoPlayer,
  },
  data() {
    return {
      videoList: [
        {
          title: 'Круг',
          text: 'Драгоценный камень',
          src: 'circle.mp4',
        },
        {
          title: 'Эмеральд',
          text: 'Драгоценный камень2',
          src: 'emerald.mp4',
        },
        {
          title: 'Кушон',
          text: 'Драгоценный камень3',
          src: 'cushion.mp4',
        },
        {
          title: 'Сердце',
          text: 'Драгоценный камень',
          src: 'heart.mp4',
        },
        {
          title: 'Ашер',
          text: 'Драгоценный камень2',
          src: 'asher.mp4',
        },
        {
          title: 'Радиант',
          text: 'Драгоценный камень3',
          src: 'radiant.mp4',
        },
      ],
      title: 'Видео',
      text:
        '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>',
    }
  },
}
</script>
<template lang="pug">
.c-video
  .container
    section.navbar
      .navbar__item
      h1.c-title {{title}}
      .navbar__item.navbar__item-right
        <router-link class="navbar__item navbar__item-right" to="/guide/" v-if="$root.window.width > 1199">
          <svg-icon name="guide"/>
          span Гид по выбору
        </router-link>
    .c-video-list
      .c-video-list__item(v-for="videoItem in videoList")
        .c-video-list__body
          video-player.c-video-list__video(ref="videoPlayer" :playsinline="true" :options="{loop:true,sources: [{type: 'video/mp4',src: require(`@/assets/video/${videoItem.src}`)}]}")
          //-video.c-video-list__video(controls loop playsinline)
            source(:src="" type="video/mp4")
        .c-video-list__title {{videoItem.title}}
        //-.c-video-list__text {{videoItem.text}}
    //-.c-video-text(v-html="text")
</template>
